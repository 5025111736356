import React from "react"
import { graphql } from "gatsby"

// import Layout from "../components/layout"
import { Location } from "@reach/router"
import SEO from "../components/seo"
import Style from "../page-styles/about.style"
import { Typography } from "../styleguide/typography"
import ConstrainedLayout from "../components/ConstrainedLayout"

import FadeInLayout from "../components/LayoutTransitions/FadeInLayout"

const { Paragraph } = Typography

interface Props {
  data: {
    allAboutJson: {
      edges: [
        {
          node: {
            background: string
            conclusion: string
            expertise: string
            intro: string
            self_taught: string
          }
        }
      ]
    }
    site: {
      siteMetadata: {
        title: string
      }
    }
  }
}

const AboutPage = ({ data }: Props) => {
  const about = data.allAboutJson.edges[0].node
  return (
    <Location>
      {({ location }) => {
        return (
          <ConstrainedLayout location={location}>
            <SEO
              title="AJ Bartocci About"
              description="Information about AJ Bartocci"
            />
            {/* <Style.Header>About Me</Style.Header> */}
            <FadeInLayout>
              <Paragraph>{about.intro}</Paragraph>
              <Paragraph>{about.background}</Paragraph>
              <Paragraph>{about.self_taught}</Paragraph>
              <Paragraph>{about.expertise}</Paragraph>
              <Paragraph>{about.conclusion}</Paragraph>
              <Style.ButtonContainer>
                <Style.LinkButton to="/contact">Contact Me</Style.LinkButton>
              </Style.ButtonContainer>
            </FadeInLayout>
          </ConstrainedLayout>
        )
      }}
    </Location>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query {
    allAboutJson {
      edges {
        node {
          background
          conclusion
          expertise
          intro
          self_taught
        }
      }
    }
  }
`
