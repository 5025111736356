import styled from "styled-components"
import LayoutComponent from "../components/layout"

const ConstrainedLayout = styled(LayoutComponent)`
  max-width: 800px;
  text-align: left;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
`

export default ConstrainedLayout
