import { Link as GatsbyLink } from "gatsby"
import styled from "styled-components"

import { Typography } from "../styleguide/typography"
import { colors } from "../styleguide/colors"
import { fontFamilies } from "../styleguide/typography-styles"

const { H1 } = Typography

const Header = styled(H1)`
  text-align: center;
`

// const Layout = styled(LayoutComponent)`
//   max-width: 800px;
//   display: inline-block;
//   text-align: left;
//   margin-top: 15px;
// `

const ButtonContainer = styled.div`
  padding: 50px;
  padding-bottom: 100px;
  text-align: center;
`

const LinkButton = styled(GatsbyLink)`
  background-color: ${colors.primary};
  color: white;
  padding: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  text-decoration: none;
  font-family: ${fontFamilies.heading};
  font-size: 18px;
  border-radius: 30px;
  height: 60px;

  &:hover {
    background-color: black;
  }
`

export default { Header, LinkButton, ButtonContainer }
